import { colors } from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';
import jsonServerProvider from 'ra-data-json-server';
import { Admin, createMuiTheme, fetchUtils, Resource } from 'react-admin';
import { Layout } from './components/Layout';
import { getConfig } from './config';
import { CustomDomainCreate } from './pages/custom-domain/CustomDomainCreate';
import { CustomDomainEdit } from './pages/custom-domain/CustomDomainEdit';
import { CustomDomainList } from './pages/custom-domain/CustomDomainList';
import { CustomDomainPathMappingCreate } from './pages/custom-domain/CustomDomainPathMappingCreate';
import { CustomDomainPathMappingEdit } from './pages/custom-domain/CustomDomainPathMappingEdit';
import { CustomDomainPathMappingList } from './pages/custom-domain/CustomDomainPathMappingList';
import { CustomDomainShow } from './pages/custom-domain/CustomDomainShow';
import { EventList, EventShow } from './pages/event';
import {
  activateInjectedScriptVersion,
  deactivateInjectedScriptVersion,
} from './pages/event-injected-script-version/data-providers';
import { EventInjectedScriptVersionCreate } from './pages/event-injected-script-version/EventInjectedScriptVersionCreate';
import { EventInjectedScriptVersionEdit } from './pages/event-injected-script-version/EventInjectedScriptVersionEdit';
import { ImpersonateTokenEdit } from './pages/impersonate-token/ImpersonateTokenEdit';
import { ImpersonateTokenList } from './pages/impersonate-token/ImpersonateTokenList';
import { ImpersonateTokenShow } from './pages/impersonate-token/ImpersonateTokenShow';
import LoginPage from './pages/LoginPage';
import { updateNameBadge } from './pages/name-badge-custom-template/NameBadgeCustomTemplateEdit';
import {
  OrganizationEdit,
  OrganizationList,
  OrganizationShow,
} from './pages/organization';
import {
  PricingPlanCreate,
  PricingPlanEdit,
  PricingPlanList,
  PricingPlanShow,
} from './pages/pricing-plans';
import {
  PublicApiTokenEdit,
  publicApiTokenShow,
} from './pages/public-api-token';
import { PublicApiTokenList } from './pages/public-api-token/PublicApiTokenList';
import {
  ReleaseControlCreate,
  ReleaseControlEdit,
  ReleaseControlList,
  ReleaseControlShow,
} from './pages/release-control';
import { SubscriptionEdit, SubscriptionShow } from './pages/subscription';
import { UserCreate, UserEdit, UserList, UserShow } from './pages/user';
import { accessTokenKey, authProvider } from './providers/authProvider';
import customRoutes from './routes/customRoutes';
import { importLeadFinderDemoUserFromCsv } from './pages/user/ImportLeadFinderDemoUser';

export const httpClient = async (
  url: string,
  options: fetchUtils.Options = {},
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const accessToken = localStorage.getItem(accessTokenKey) ?? '';
  options.headers = options.headers as Headers;
  options.headers.set('Authorization', `Bearer ${accessToken}`);

  return fetchUtils.fetchJson(url, options);
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.red['600'],
    },
    secondary: {
      main: colors.deepPurple['900'],
    },
  },
});

const App = () => {
  const dataProvider = jsonServerProvider(
    process.env.REACT_APP_API_BASE_URL ?? '',
    httpClient,
  );

  const customDataProvider = {
    ...dataProvider,
    updateNameBadge,
    activateInjectedScriptVersion,
    deactivateInjectedScriptVersion,
    importLeadFinderDemoUserFromCsv,
  };

  return (
    <Admin
      theme={theme}
      layout={Layout}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={customDataProvider}
      loginPage={LoginPage}
    >
      <Resource name="events" list={EventList} show={EventShow} />
      <Resource
        name="organizations"
        list={OrganizationList}
        show={OrganizationShow}
        edit={OrganizationEdit}
      />
      <Resource
        name="pricing-plans"
        list={PricingPlanList}
        show={PricingPlanShow}
        create={PricingPlanCreate}
        edit={PricingPlanEdit}
      />
      <Resource
        name="release-controls"
        list={ReleaseControlList}
        show={ReleaseControlShow}
        create={ReleaseControlCreate}
        edit={ReleaseControlEdit}
      />
      <Resource
        name="impersonate-tokens"
        list={ImpersonateTokenList}
        show={ImpersonateTokenShow}
        edit={ImpersonateTokenEdit}
      />
      <Resource
        name="subscriptions"
        show={SubscriptionShow}
        edit={SubscriptionEdit}
      />
      <Resource
        name="public-api-tokens"
        list={PublicApiTokenList}
        show={publicApiTokenShow}
        edit={PublicApiTokenEdit}
      />
      <Resource name="event-managers" />
      <Resource name="organization-managers" />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        show={UserShow}
        edit={UserEdit}
      />
      <Resource name="webinar-sessions" />
      <Resource name="booths" />
      <Resource name="credit-system/balance" />
      <Resource name="credit-system/transactions" />
      <Resource name="impersonate-tokens/event" />
      <Resource
        name="custom-domains"
        list={CustomDomainList}
        create={CustomDomainCreate}
        edit={CustomDomainEdit}
        show={CustomDomainShow}
      />
      <Resource
        name="custom-domain-path-mappings"
        list={CustomDomainPathMappingList}
        create={CustomDomainPathMappingCreate}
        edit={CustomDomainPathMappingEdit}
      />
      <Resource
        name="event-injected-script-versions"
        create={EventInjectedScriptVersionCreate}
        edit={EventInjectedScriptVersionEdit}
      />
    </Admin>
  );
};

const AppWithGoogleAuth = () => {
  return (
    <GoogleOAuthProvider clientId={getConfig().gcp.oauthClientId}>
      <App />
    </GoogleOAuthProvider>
  );
};

export default AppWithGoogleAuth;
