import React, { useEffect, useState } from 'react';
import { Box, Divider, styled } from '@material-ui/core';
import nameBadgeLogo from '../images/name-badge-logo.png';
import nameBadgeQRCode from '../images/name-badge-qr-code.png';
import { httpClient } from '@/App';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash-es';

interface Props {
  layoutConfig: NameBadgeTemplateLayoutConfig;
}

export interface NameBadgeTemplateLayoutConfig {
  width: number;
  height: number;
  elements: NameBadgeElement[];
  printerType: PrinterType;
  brotherLabelSize?: number;
}

enum PrinterType {
  BROTHER = 'brother',
  ZEBRA = 'zebra',
}

enum CustomFieldType {
  BASIC_FIELD = 'basic_field',
  CUSTOM_FIELD = 'custom_field',
  TICKET = 'ticket',
  CUSTOM_TEXT_FIELD = 'custom_text_field',
}

enum ShapeType {
  RECTANGLE = 'rectangle',
  TRIANGLE = 'triangle',
  CIRCLE = 'circle',
}

enum NameBadgeElementType {
  TEXT = 'text',
  IMAGE = 'image',
  SHAPE = 'shape',
}

export interface NameBadgeElement {
  field: string; // all ticket => field = 'tickets'
  fieldType: CustomFieldType;
  type: NameBadgeElementType;
  imageUrl?: string;
  style: ElementStyle;
  shapeType: ShapeType;
}

export interface ElementStyle {
  left: number;
  top: number;
  width: number;
  height: number;
  fontSize?: number;
  fontWeight?: 'bold' | 'normal';
  textAlign?: 'left' | 'center' | 'right';
  lineHeight?: number;
  numberOfLines?: number;
  rotate?: number;
  color?: string;
}

interface TicketClass {
  id: string;
  name: string;
}

interface QuestionLibrary {
  id: string;
  label: string;
}

const predefinedFieldNames = [
  'name',
  'email',
  'phone',
  'jobTitle',
  'organization',
  'country',
  'city',
];

const ShapeElementWrapper = styled('div')({
  position: 'absolute',
  overflow: 'hidden',
  width: ' 100%',
  height: ' 100%',
  background: '#000',
  '&.triangle': {
    clipPath: 'polygon(0% 100%, 50% 0%, 100% 100%)',
  },
  '&.circle': {
    borderRadius: '100%',
  },
});

export const NameBadgePreview: React.FC<Props> = ({ layoutConfig }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [fieldMap, setfieldMap] = useState<Record<string, string>>({});

  const getTickets = async () => {
    const res = (await httpClient(
      `${process.env.REACT_APP_API_BASE_URL ?? ''}/events/${eventId}/ticketing`,
      {
        method: 'GET',
      },
    )) as { json: TicketClass[] };

    if (res.json.length) {
      for (const ticketClass of res.json) {
        fieldMap[ticketClass.id] = ticketClass.name;
      }

      fieldMap.tickets = 'all tickets';

      setfieldMap(fieldMap);
    }
  };

  const getCustomFields = async () => {
    const res = (await httpClient(
      `${
        process.env.REACT_APP_API_BASE_URL ?? ''
      }/events/${eventId}/question-library`,
      {
        method: 'GET',
      },
    )) as {
      json: QuestionLibrary[];
    };

    if (res.json.length) {
      for (const question of res.json) {
        if (predefinedFieldNames.includes(question.id)) {
          continue;
        }

        fieldMap[question.id] = question.label;
      }

      setfieldMap(fieldMap);
    }
  };

  const generateDefaultFieldMap = () => {
    for (const item of predefinedFieldNames) {
      fieldMap[item] = item;
    }

    setfieldMap(fieldMap);
  };

  useEffect(() => {
    generateDefaultFieldMap();
    void getTickets();
    void getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          marginLeft: 10,
          width: layoutConfig.width,
          height: layoutConfig.height,
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
        }}
      >
        {layoutConfig.elements.map(
          ({ type, field, fieldType, imageUrl, style, shapeType }, index) => {
            if (type === NameBadgeElementType.TEXT) {
              return (
                <Box
                  key={field + String(index)}
                  sx={{
                    ...style,
                    border: '1px dashed #999',
                    position: 'absolute',
                    ...(style.lineHeight && {
                      lineHeight: `${style.lineHeight}px`,
                    }),
                  }}
                  style={{
                    transform: `rotate(${style.rotate ?? 0}deg)`,
                  }}
                >
                  <div
                    style={{
                      display: '-webkit-box',
                      wordBreak: 'break-all',
                      WebkitLineClamp: style.numberOfLines,
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {fieldType === CustomFieldType.CUSTOM_TEXT_FIELD
                      ? field
                      : fieldMap[field]}
                  </div>
                </Box>
              );
            }

            if (type === NameBadgeElementType.IMAGE) {
              let src;
              switch (field) {
                case 'eventLogo':
                  src = nameBadgeLogo;
                  break;
                case 'qrcode':
                  src = nameBadgeQRCode;
                  break;
                default:
                  src = imageUrl;
              }

              return (
                <img
                  key={field}
                  style={{
                    ...omit(style, 'rotate'),
                    transform: `rotate(${style.rotate ?? 0}deg)`,
                    position: 'absolute',
                  }}
                  src={src}
                  alt={field ?? 'customImage'}
                />
              );
            }

            if (type === NameBadgeElementType.SHAPE)
              return (
                <ShapeElementWrapper
                  className={shapeType}
                  style={{
                    ...omit(style, 'rotate'),
                    transform: `rotate(${style.rotate ?? 0}deg)`,
                    position: 'absolute',
                  }}
                />
              );
            return null;
          },
        )}
        {(layoutConfig.printerType === PrinterType.ZEBRA ||
          !layoutConfig.brotherLabelSize) && (
          <Divider
            absolute
            style={{
              top: layoutConfig.height / 2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
